/* You can add global styles to this file, and also import other style files */
/*@import "~bootstrap/sami/dist/css/bootstrap.css";*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import '~ngx-toastr/toastr';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~nouislider/dist/nouislider.min.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";

@import "~mobile-drag-drop/default.css";

:root {
    --colorWhite: #FFFFFF;
    --colorLightGrey: #F6F8FC;
    --accentColor: #4AE6D7;
    --accentColorLight: #B3F8F1;
    --accentColorDark: #4CCCC0;
    --accentColorHover: rgba(74, 230, 215, 0.5);
    --accentColorTransparent: #4ae6d633;
    --boxBackgroundColor: var(--colorWhite);
    --lightBackgroundColor: var(--colorLightGrey);
    --darkerBackgroundColor: #EBEEF6;
    --darkestBackgroundColor: #e0e3ec;
    --textColor: #212529;
    --textColorLight: grey;
    --textOnAccentColor: #212529;
    --fontFamily: 'Montserrat';
}

html, body {
    background-color: var(--lightBackgroundColor);
    font-family: var(--fontFamily);
    font-weight: 400;
    font-size: 15px;
    color: var(--textColor)
}
.color-onaccent {
  color: var(--textOnAccentColor)
}

.html-on-native-mobile {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hidden {
  visibility: collapse;
}

.visible {
  visibility: visible;
}

.position-relative {
  position: relative;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.visibility-hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.display-inlineblock {
  display: inline-block;
}

.display-inline {
  display: inline !important;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.clear-both {
  clear: both;
}

.justifycontent-left {
  justify-content: left;
}

.justifycontent-center {
  justify-content: center;
}

.justifycontent-space-between {
  justify-content: space-between;
}

.justifycontent-right {
  justify-content: right;
}

.fontweight-bold, .bold {
  font-weight: 700 !important;
}

.textalign-center {
  text-align: center;
}

.montserrat {
  font-family: var(--fontFamily);
  font-weight: 500;
}

.backgroundcolor-accent {
  background-color: var(--lightBackgroundColor);
}

.color-accent {
  color: var(--accentColor);
}

div.spacer-5 {
  width: 100%;
  height: 5px;
}

div.spacer-10 {
  width: 100%;
  height: 10px;
}

div.spacer-15 {
  width: 100%;
  height: 15px;
}

div.spacer-20 {
  width: 100%;
  height: 20px;
}

div.spacer-25 {
  width: 100%;
  height: 25px;
}

div.spacer-40 {
  width: 100%;
  height: 40px;
}

div.spacer-50 {
  width: 100%;
  height: 50px;
}

div.spacer-100 {
  width: 100%;
  height: 100px;
}


button.send-comment {
  height: 40px;
  width: 40px;
  padding: 0;
  float: left;
  margin: 0 0 0 10px;
  background-color: var(--accentColor);
  opacity: 0.5;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: var(--fontFamily);
  font-weight: 700;
}

.profile-picture {
  display: block;
  background-color: #c8c8c8;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  overflow: hidden;
  float: left;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-person-circle" viewBox="0 0 16 16"><path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/><path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/></svg>');
  background-size: cover;
  background-repeat: no-repeat;
}

.profile-picture img {
  width: 100%;
  height: auto;
  border-radius: 25px;
}

.profile-button {
  padding: 0px;
}

h3.name {
  float: left;
  margin: 15px 0 0 15px;
}

h3.profile-name {
  width: auto;
  display: inline-block;
  margin: 15px 0 0 15px;
}

.small-text {
  font-size: 10px;
}

.margin-4 {
  margin: 4px;
}

.marginleft-0 {
  margin-left: 0px !important;
}

.marginleft-50 {
  margin-left: 50px;
}

.marginleft-25 {
  margin-left: 25px;
}

.marginleft-20 {
  margin-left: 20px !important;
}
.marginleft-15 {
    margin-left: 15px;
}
.marginleft-10 {
  margin-left: 10px;
}

.marginleft-5 {
  margin-left: 5px;
}

.marginhorizontal-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-left-right {
  margin-left: 5%;
  margin-right: 5%;
}

.marginright-5 {
  margin-right: 5px;
}

.marginright-10 {
  margin-right: 10px;
}

.marginright-25 {
  margin-right: 25px;
}

.marginright-20 {
  margin-right: 20px;
}

.marginbottom-30 {
  margin-bottom: 30px;
}

.marginbottom-25 {
  margin-bottom: 25px;
}

.marginbottom-15 {
  margin-bottom: 15px;
}

.marginbottom-10 {
  margin-bottom: 10px;
}

.marginbottom-5 {
  margin-bottom: 5px !important;
}

.margintop-10 {
  margin-top: 10px;
}

.margintop-20 {
  margin-top: 20px;
}

.margin-auto {
  margin: auto !important;
}

@media (max-width: 576px) {
  .mobile-marginleft-10 {
    margin-left: 10px;
  }

  .mobile-marginleft-0 {
    margin-left: 0px;
  }

  .mobile-margintop-10 {
    margin-top: 10px;
  }

  .mobile-marginbottom-10 {
    margin-bottom: 10px !important;
  }

  .mobile-display-none {
    display: none;
  }

  app-md-editor-legacy.single-line #editor textarea {
    height: 72px;
  }
}

.padding-4 {
  padding: 4px;
}

.padding-6 {
  padding: 6px;
}

.padding-15 {
  padding: 15px;
}

.padding-25 {
  padding: 25px;
}

.width-0 {
  width: 0px;
}

.width-100 {
  width: 100%;
}

.width-100px {
  width: 100px !important;
}

.width-auto {
  width: auto !important;
}

.paddingbottom-10 {
  padding-bottom: 10px;
}

.paddingbottom-safezone {
  padding-bottom: calc(env(safe-area-inset-bottom));
}

.paddingleft-10 {
  padding-left: 10px;
}

.container-white {
  background-color: var(--boxBackgroundColor);
  box-shadow: 0 0 0 15px var(--boxBackgroundColor);
  border-radius: 25px;
}

small, .small {
  font-weight: inherit;
  font-size: inherit;
}

.color-red {
  color: #F90C7B;
}

.color-blue {
  color: #31C1FF;
}

.color-yellow {
  color: #fff131;
}

.color-black {
  color: black !important;
}

.underline {
  text-decoration: underline;
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: var(--textColorLight);
}

.label-top {
  background-color: var(--accentColor);
  color: black;
  padding: 2px 5px;
  display: inline-block;
  margin: 0 0 0px 5px;
  transform: translateY(-10px);
}

.fontsize-small {
  font-size: 12px;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.margintop-20-xs {
  margin-top: 20px;
}

@media (min-width: 576px) {
  .col-sm-0 {
    display: none;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .margintop-20-xs {
    margin-top: 0px;
  }
}

@media (min-width: 768px) {
  .col-md-0 {
    display: none;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }
}

@media (min-width: 992px) {
  .col-lg-0 {
    display: none;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }
}

@media (min-width: 1200px) {
  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }
}

@media (min-width: 1400px) {
  .row .col-xxl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .row .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .row .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row .col-xxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row .col-xxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .row .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .row .col-xxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }
}

@media (min-width: 1650px) {
  .row .col-xxxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .row .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row .col-xxxl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .row .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row .col-xxxl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .row .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .row .col-xxxl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .order-xxxl-1 {
    order: 1;
  }

  .order-xxxl-2 {
    order: 2;
  }

  .order-xxxl-3 {
    order: 3;
  }
}

label {
  margin-bottom: 10px;
}

label.mdc-label {
  margin-bottom: 0;
}

.mdc-radio__outer-circle {
  border-color: var(--accentColor) !important;
}

.mdc-radio__inner-circle {
  border-color: var(--accentColor) !important;
}


.page-content {
  width: calc(100% - 70px);
  float: right;
  padding: 25px 25px 0 25px;
  overflow: visible;

  /* iPhone notch */
  margin-top: env(safe-area-inset-top);
}

input[disabled] {
  pointer-events: none
}

.cursor-pointer {
  cursor: pointer;
}

.toggle-wrapper {
  vertical-align: middle;
  margin-left: 20px;
}

.toggle-wrapper .left-text {
  font-size: 10px;
  display: inline;
  font-weight: 700;
  outline: none !important;
  float: left;
  margin: 2px 10px 0 0px;
}

.toggle-disabled {
  opacity: 0.6;
}

.dual-switch {
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
}

.dual-switch .option {
  font-size: 13px;
  background-color: var(--darkerBackgroundColor);
  padding: 5px 10px;
  display: inline-block;
}

.dual-switch .option.active {
  background-color: var(--accentColor);
}

.mat-mdc-menu-panel {
  border-radius: 20px !important;
  font-size: 14px;
  font-family: var(--fontFamily);
  letter-spacing: 0 !important;
  background-color: var(--lightBackgroundColor) !important;
}

.mat-mdc-menu-item {
  min-height: unset !important;
  padding: 10px 30px 10px 20px !important;
}

.mat-mdc-menu-item, .mat-mdc-menu-item-text {
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
  letter-spacing: 0 !important;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  white-space: normal;
  font-family: unset !important;
  line-height: unset !important;
  font-size: unset !important;
  letter-spacing: unset !important;
  font-weight: unset !important;
}

.mat-datepicker-toggle {
  color: var(--textColor) !important;
}

/* NAVIGATION BAR */

@media (max-width: 991.98px) {
  .page-content {
    width: 100%;
    padding-top: 15px;
    /* padding: 15px 0 30px 0px; */
    padding-left: 0;
    padding-right: 0;
    padding-bottom: calc(30px + env(safe-area-inset-bottom));

  }
}

/* BUTTONS AND INPUTS */

button, .button, a {
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none !important;
  color: var(--textColor);
}
app-notes a {
    color: var(--accentColor);
}
.button-filled {
  min-height: 40px;
  width: auto;
  padding: 0 40px;
  background-color: var(--accentColor);
  color: var(--textOnAccentColor);
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-family: var(--fontFamily);
  font-weight: 700;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.2, 0, 0.3, 1);
  outline: none !important;
}

.traindoo-app .button-filled {
  padding: 8px 25px;
  border-radius: 10px;
}

.button-filled:hover {
  background-color: var(--accentColor);
  color: var(--textOnAccentColor);
  -webkit-box-shadow: 2px 3px 20px 0px var(--accentColorHover);
  -moz-box-shadow: 2px 3px 20px 0px var(--accentColorHover);
  box-shadow: 2px 3px 20px 0px var(--accentColorHover);
}

.traindoo-app .button-filled:hover {
  -webkit-box-shadow: 2px 3px 10px 0px var(--accentColorHover);
  -moz-box-shadow: 2px 3px 10px 0px var(--accentColorHover);
  box-shadow: 2px 3px 10px 0px var(--accentColorHover);
}

.button-filled.light {
  background-color: var(--lightBackgroundColor);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: var(--textColor);
}

.button-filled.light:hover {
  background-color: var(--lightBackgroundColor);
}

.traindoo-app .button-filled.light:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.button-filled.white, .traindoo-app .button-filled.white {
  background-color: var(--boxBackgroundColor);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: var(--textColor);
}

.button-filled.white:hover {
  background-color: var(--boxBackgroundColor);
}

.button-filled.small {
  min-height: 30px;
  padding: 0 30px;
  border-radius: 20px;
  font-size: 14px;
}

.traindoo-app .button-filled.small {
  padding: 8px 25px;
  border-radius: 10px;
}

a.button-filled.small {
  padding: 4px 30px;
}

.button-filled.small.dropdown-toggle {
  padding: 0 15px;
}

.button-filled.text-centered {
  text-align: center;
}

.button.margin-inbetween, button.margin-inbetween, .button-filled.margin-inbetween {
  margin: 0 10px 10px 0;
}

.button-squared {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 250px;
  height: 250px;
  background-color: white;
  border-radius: 25px;
  border: none;
  font-weight: 700 !important;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
}

.icon-button {
  font-size: 10px;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  outline: none !important;
}

.icon-button svg {
  font-size: 30px;
}

.icon-button.small svg {
  padding: 4px;
}

@media (max-width: 576px) {
  .icon-button .icon-button-text {
    display: none;
  }
}

.tab-button {
  background-color: var(--darkerBackgroundColor);
  color: var(--textColor);
  margin: 5px;
  padding: 5px 10px;
  border-radius: 10px;
}

.tab-button.active {
  background-color: var(--accentColor);
  color: var(--textOnAccentColor);
}

.tab-button.active .underline, .tab-button:hover .underline {
  background-color: black;
}

.date-selection .button.change-date {
  width: 30px;
  height: 30px;
  background-color: var(--boxBackgroundColor);
  color: var(--textColor);
  border-radius: 25px;
  border: none;
}

@media (max-width: 576px) {
  .tab-button-group {
    padding: 0px !important;
  }

  .tab-button-group.adjust-mobile .tab-button {
    font-size: 12px;
  }
}

.icon-text {
  font-size: 12px;
  display: inline;
  font-weight: 500;
  outline: none !important;
}

.icon-text svg {
  font-size: 30px;
}

.icon-text.small svg {
  padding: 4px;
}

.small-close-button {
  position: absolute;
  display: none;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  font-size: 25px;
  background-color: var(--lightBackgroundColor);
  border-radius: 15px;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
}

.small-close-button-wrapper:hover .small-close-button {
  display: block;
}

.small-close-button svg {
  margin-top: -15px;
}

input, textarea, .input {
  width: 75px;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: var(--lightBackgroundColor);
  color: var(--textColor);
  outline: none !important;
}

input.small {
  width: 70px;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--lightBackgroundColor);
}

input.medium {
  width: 150px;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--lightBackgroundColor);
}

app-nutritional-goal-setup input.small {
  background-color: var(--boxBackgroundColor) !important;
}

.expert-mode app-nutritional-goal-setup input.small {
  background-color: var(--lightBackgroundColor) !important;
}

input.wide, textarea.wide {
  width: 100%;
  max-width: 300px;
}

textarea.full-width, input.full-width {
  width: 100%;
  max-width: unset;
}

app-macro-slider .white-fields input.input-nutrition {
  background-color: var(--boxBackgroundColor) !important;
}

app-macro-slider .white-fields .noUi-target, app-macro-slider .white-fields .noUi-connect {
  background-color: var(--boxBackgroundColor) !important;
}

.expert-mode app-nutritional-goal-setup input.input-multiplier {
  background-color: var(--boxBackgroundColor) !important;
}

@media (max-width: 576px) {
  .input.wide {
    max-width: 230px;
  }

  input.small {
    width: 60px;
    font-size: 13px;
  }
}

input.date {
  width: 100px;
}

input.full-date {
  width: 120px;
}

input.white, textarea.white {
  background-color: var(--boxBackgroundColor);
  color: var(--textColor);
}

.textalign-right {
  text-align: right;
}

.textalign-left {
  text-align: left;
}

input.ng-invalid, textarea.ng-invalid, input.invalid, textarea.invalid, button.invalid {
  border: 1px solid red;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input[type="checkbox"] {
  width: inherit;
  accent-color: var(--accentColor);
}

input.wide-input {
  width: 100%;
}

input.pause-input {
  width: 55px;
}

ngx-link input {
    width: 100%;
}

.form-control:disabled, .form-control[readonly] {
  background-color: var(--lightBackgroundColor);
  color: var(--textColor);
}

.button-invalid {
  border: 1px solid red;
}

.caution {
  color: red;
  font-weight: bold;
}

.button-rectangle {
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 5%;
  margin-right: 5%;
  width: 300px;
  height: 225px;
  background-color: var(--boxBackgroundColor);
  border-radius: 25px;
  border: none;
  font-weight: 700 !important;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
}

.button-rectangle-inner {
  position: relative;
  top: 0;
  left: 0;
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  background-color: var(--boxBackgroundColor) !important;
  color: var(--textColor) !important;
  border: 1px solid var(--darkestBackgroundColor);
}

.dropdown-item {
  color: var(--textColor) !important;
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item.selected {
  color: var(--textColor);
  text-decoration: none;
  background-color: var(--lightBackgroundColor);
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--lightBackgroundColor);
  color: var(--textColor);
}

.dropdown-menu .dropdown-item:has(input.checkbox) {
  padding: 0px;
}

.dropdown-menu input.checkbox {
  width: 10px;
  margin: 10px;
}

button.mat-menu-item {
  color: var(--textColor);
  text-decoration: none;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  background-color: var(--boxBackgroundColor);
}

button.mat-menu-item:hover {
  background-color: #F0F3F8;
}

.mat-mdc-menu-item, .mat-mdc-menu-item:visited, .mat-mdc-menu-item:link {
  color: var(--textColor) !important;
}

.card {
  background-color: var(--boxBackgroundColor);
}

.toggle-wrapper .toggle {
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 15px;
  background-color: var(--darkestBackgroundColor);
  cursor: pointer;
  float: left;
  flex: 0 0 auto;
}

.toggle-wrapper.on-background .toggle {
  background-color: var(--darkerBackgroundColor);
}

.toggle-wrapper .toggle.on {
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 15px;
  background-color: var(--accentColor);
  cursor: pointer;
}

.toggle-wrapper .toggle .handle {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 1px;
  border-radius: 15px;
  background-color: white;
}

.toggle-wrapper .toggle.on .handle {
  left: 21px;
}

.toggle-wrapper .text {
  font-size: 10px;
  display: inline;
  font-weight: 700;
  outline: none !important;
  float: left;
  margin: 2px 0 0 10px;
}

.toggle-wrapper.icon-toggle .toggle {
  height: 30px;
  width: 60px;
}

.toggle-wrapper.icon-toggle .toggle.on {
  background-color: var(--darkestBackgroundColor);
}

.toggle-wrapper.icon-toggle .toggle .handle {
  width: 28px;
  height: 28px;
  border-radius: 15px;
  background-color: white;
}

.toggle-wrapper.icon-toggle .toggle.on .handle {
  left: 31px;
}

.toggle-wrapper.icon-toggle .toggle-icon {
  position: absolute;
  height: 30px;
  width: 40px;
  padding: 3px 0 0 8px;
  color: black;
}

.toggle-wrapper.icon-toggle .toggle-icon.right {
  color: var(--textColor);
}

.toggle-wrapper.icon-toggle .toggle-icon.right {
  left: 29px
}

.toggle-wrapper.icon-toggle .toggle.on .toggle-icon.left {
  color: var(--textColor);
}

.toggle-wrapper.icon-toggle .toggle.on .toggle-icon.right {
  color: black;
}

div.icon {
  display: inline;
  margin: 5px;
}

/* App Loading Screen */
#app-loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--lightBackgroundColor);
  z-index: 96;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

#app-loading .progress {
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  height: 20px;
  width: 200px;
  margin: 40vh auto 0 auto;
}

#app-loading .progress-value {
  animation: load 10s normal forwards;
  border-radius: 100px;
  background: var(--accentColor);
  height: 20px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  20% {
    width: 50%;
  }
  50% {
    width: 65%;
  }
  100% {
    width: 75%;
  }
}

.rotate-easter-egg {
  transition: transform .6s ease-in-out;
}

.rotate-easter-egg:hover {
  transform: rotate(360deg);
}

/* ALGOLIA SEARCH */

ais-search-box {
  width: calc(100% - 30px);
  display: inline-block;
}

input.ais-SearchBox-input {
  width: calc(100% - 50px);
}

button.ais-SearchBox-submit, button.ais-SearchBox-reset {
  display: none;
}

button.ais-SearchBox-reset {
  cursor: pointer !important;
  border: none !important;
  background-color: transparent !important;
}

button.ais-SearchBox-reset svg {
  height: 15px;
}

div.ais-SearchBox {
  background-color: var(--lightBackgroundColor);
  border-radius: 25px;
}

div.ais-Hits {
  background-color: var(--lightBackgroundColor);
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}

ul.ais-Hits-list {
  list-style: none;
  padding-left: 20px;
  font-size: 14px;
}

li.ais-Hits-item {
  padding: 5px 0;
}

ul.ais-Pagination-list {
  list-style: none;
  padding-left: 20px;
  font-size: 14px;
  float: right;
}

li.ais-Pagination-item {
  padding: 5px;
  display: inline;
}

.indicator {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: var(--accentColor);
  border-radius: 15px;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
}

.indicator.small {
  height: 15px;
  width: 15px;
  font-size: 10px;
  text-align: center;
}

.indicator.dynamic-width {
  width: unset;
  min-width: 15px;
  padding: 0 3px;
}

.error-message {
  font-size: 12px;
  color: #EA405D;
}

.toast-success {
  background-color: var(--accentColor) !important;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 576px) {
  .only-desktop {
    display: none;
  }

  .only-mobile {
    display: inherit;
  }
}

@media (min-width: 576px) {
  .only-desktop {
    display: inherit;
  }

  .only-mobile {
    display: none;
  }
}

.padding-0 {
  padding: 0 !important;
}

.padding-10 {
  padding: 10px;
}

.margin-0 {
  margin: 0 !important;
}

.margin-25 {
  margin: 25px;
}

.margin-10 {
  margin: 10px;
}

.margin-10-25 {
  margin: 10px 25px;
}

.margin-vertical-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.link {
  cursor: pointer;
  outline: none !important;
}

h2, .h2 {
  font-size: 22px;
  font-family: var(--fontFamily);
  font-weight: 700;
  margin: 0 0 30px 0;
}

h3, .h3 {
  font-size: 16px;
  font-family: var(--fontFamily);
  font-weight: 700;
  margin: 0 0 15px 0;
}

h4, .h4 {
  font-size: 24px;
  font-family: var(--fontFamily);
  font-weight: 500;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

/* STYLE COMPONENTS */

.video-box {
  padding: 0px !important;
  overflow: hidden;
}

.video-box video {
  max-height: 500px;
  max-width: 400px;
}

.layout-box {
  border-radius: 25px;
  background-color: var(--boxBackgroundColor);
  padding: 25px 30px;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 15px 0;
  outline: none !important;
  position: relative;
}

@media (width >= 1000px) {
  .statistic-box {
    background-color: var(--lightBackgroundColor);
    margin: 0 0 10px 0;
    border-radius: 25px;
    padding: 15px 20px;
    outline: none !important;
    position: relative;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}


.traindoo-app .layout-box {
  box-shadow: 1px 6px 10px 0px rgba(0, 0, 0, 0.05);
}

.layout-box.sub-box {
  background-color: var(--lightBackgroundColor);
  padding: 15px 20px !important;
  margin: 0 0 10px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.traindoo-app .layout-box.sub-box {
  background-color: transparent !important;
  border-radius: 0px;
  border-bottom: 2px solid #EEF2F6;
}

.layout-box.layout-box.sub-box.dropzone {
  padding: 30px 40px;
  text-align: center;
  border: 4px dashed #dfe2eb;
}

.layout-box.turquoise {
  background-color: var(--accentColor);
  color: var(--textOnAccentColor);
}

.layout-box.turquoise .icon-button {
  color: var(--textOnAccentColor);
}

@media (max-width: 991.98px) {
  .adjust-mobile {
    padding: 15px 20px;
  }
}

.layout-box.transparent {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.table {
  padding: 0 15px;
}

.table .table-item {
  font-size: 14px;
  padding: 6px 0;
  border-bottom: 1px solid #e8e8e8;
}

.table .table-item.table-header {
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #dcdcdc;
}

.table .table-item:hover {
  background-color: var(--lightBackgroundColor);
}

.table .table-item .col:first-child {
  padding-left: 5px;
}

.table .table-item .col:last-child {
  padding-right: 5px;
}

.status-indicator-wrapper {
  font-size: 12px;
  display: inline-block;
  border-radius: 10px;
  padding: 4px 8px;
}

.status-indicator {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 0 3px 0 0;
  border-radius: 10px;
}

/* CHAT */

chat {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 85px);
  z-index: 1001;
}

chat .chats-overview {
  position: relative;

}

chat .single-chats {
  position: relative;
}

single-chat {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 0 15px;
  z-index: 99;
}

single-chat .card-container {
  /* iPhone notch */
  /* margin-top: env(safe-area-inset-top); */
  margin-top: 15px;
}

.layout-box.chat-box {
  background-color: var(--lightBackgroundColor);
  padding: 15px 20px !important;
  margin: 0 0 10px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.chat-box .chat-name {
  font-weight: 700 !important;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
}

.chat-box .message-preview {
  font-weight: 500 !important;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
}

@media (max-width: 991.98px) {
  chat {
    width: 100%;
  }

  chat .single-chats {
    width: 100%;
    padding: 0;
  }

  single-chat {
    padding: 0;
    left: 0;
  }
}

/* DIALOGS */

.dialog-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 96;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  top: 0;
  left: 0;
}

.dialog {
  display: none;
  position: fixed;
  z-index: 96;
  width: 80%;
  max-width: 500px;
  padding: 0px !important;
  margin: 0px;

  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  /* iPhone notch */
  margin-top: env(safe-area-inset-top);
}

.mat-mdc-dialog-surface {
  padding: 0px !important;
  border-radius: 25px !important;
  background: #FFFFFF;
}

.mdc-dialog__container {
  padding: 0px !important;
  border-radius: 25px !important;
  background: #FFFFFF;
}

.mat-mdc-dialog-container {
  padding: 0px !important;
  border-radius: 25px !important;
  background: #FFFFFF;
}

.dialog-wrapper.show {
  display: block;
}

.dialog-wrapper.show .dialog, .dialog.show {
  display: block;
  width: 100%;
  max-width: 800px;
}

@media (max-width: 767.98px) {
  .dialog.mobile-fullscreen {
    height: 100%;
  }

  .dialog {
    /* iPhone notch */
    margin-top: env(safe-area-inset-top);
  }
}

.dialog-topbar {
  position: fixed;
  padding: 10px 20px;
  background: var(--lightBackgroundColor);
  display: block;
  z-index: 96;
  border-radius: 25px 25px 0 0;
  width: 100%;
  min-height: 50px;
}

.dialog-content-wrapper {
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  margin: 50px 0 0 0;
  padding: 25px;
}

@media (max-width: 991.98px) {
  .dialog-content-wrapper {
    padding: 15px;
  }
}

.dialog .header .icon-button.close {
  margin-top: -5px;
}

.dialog .actions {
  margin-top: 25px;
}

.dialog .actions div.button, .dialog .actions button {
  margin-left: 25px;
}

dialog {
  border: none;
}

.mat-mdc-dialog-container .dialog-topbar {
  position: inherit;
}

.mat-mdc-dialog-container .dialog-content-wrapper {
  margin: 0px;
}

.mat-mdc-dialog-container .mat-dialog-actions {
  margin: 0px !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface, .mat-mdc-dialog-container {
  background-color: var(--boxBackgroundColor) !important;
}

.chip {
  font-size: 12px;
  border-radius: 20px;
  background-color: var(--lightBackgroundColor);
  width: auto;
  display: inline-block;
  padding: 5px 10px;
  margin: 10px 10px 0 0;
}

.chip.selected, .chip.active {
  background-color: var(--accentColor);
  color: var(--textOnAccentColor)
}

.chip.white {
  background-color: white;
}

app-md-editor-legacy.min-height #editor textarea {
  min-height: 300px !important;
}

app-md-editor-legacy.single-line #editor textarea {
  height: 50px;
}

app-md-editor-legacy.multi-line #editor textarea {
  height: 140px;
}

app-md-editor-legacy#editor-instructions #editor {
  height: 150px;
}

app-md-editor-legacy.mdeditor-whitefield textarea {
  background-color: var(--boxBackgroundColor) !important;
  color: var(--textColor) !important;
}

@media (max-width: 576px) {
  app-md-editor-legacy.single-line #editor textarea {
    height: 72px;
  }
}

popover-container {
  max-width: unset !important;
}

tr.dx-selection {
  opacity: 0.3 !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
  background-color: white !important;
}

.dx-datagrid, .dx-toolbar, .dx-datagrid .dx-datagrid-content-fixed .dx-datagrid-table .dx-col-fixed, .dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  background-color: transparent;
  color: var(--textColor);
}

.dx-datagrid-summary-item {
  color: var(--textColorLight);
}

.datepicker-button .mat-icon-button {
  width: 25px !important;
}

.mat-mdc-icon-button svg {
  vertical-align: middle !important;
}

.spinner, .mdc-circular-progress__circle-clipper circle, .mat-spinner circle, .mdc-circular-progress__circle-clipper svg {
  stroke: var(--textColor) !important;
  margin: auto;
}

.loading-statistics-spinner, .loading-statistics-spinner circle {
  stroke: #4AD2DC !important;
  margin: auto;
}

.cdk-global-scrollblock {
  position: static !important;
  width: initial !important;
  overflow-y: hidden !important;
}

/* NoUiSlider */
.noUi-target, .noUi-connect {
  transform: none !important;
  text-align: center;
  font-family: var(--fontFamily);
  font-weight: 700;
  font-size: 13px;
  background-color: var(--lightBackgroundColor);
  border: none;
  border-radius: 20px;
  overflow: visible !important;
}

.noUi-handle {
  background-color: var(--accentColor);
  border: none;
  box-shadow: none;
  width: 20px !important;
  top: -5px !important;
}

.noUi-handle::before {
  left: 7px;
}

.noUi-handle::after {
  left: 12px;
}

.noUi-connects {
  overflow: visible;
}

.noUi-connect .mealtype-label {
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -38px;
}


.drag-handle {
  display: inline-block;
  float: left;
}

.spinner-overlaytext {
  padding: 20px;
  background-color: var(--accentColor);
  text-align: center;
  color: white;
}

app-nutrition-plan {
  width: 100%;
}

app-notification-composer {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 5;
}

.container-left-align {
  margin-left: 0;
}

.thumbnail-image {
  width: 65px;
  margin: -10px 20px -10px 0;
  position: relative;
  height: 65px;
}

.thumbnail-image img {
  height: 100%;
  width: 100%;
}

@media (max-width: 576px) {
  .thumbnail-image {
    width: 50px;
    margin: 0px 10px -10px 0;
    position: relative;
    height: 50px;
  }
}

#cdk-overlay-1:not(.training-tracking-dialog), .cdk-overlay-pane:not(.training-tracking-dialog) {
  max-width: 94vw !important;
}

.cdk-overlay-pane.training-tracking-dialog {
  max-width: 1000px !important;
}

/* .cdk-overlay-container {
    z-index: 99;
} */

/* .chat-cdk-overlay-container {
    z-index: 100;
} */

.cdk-overlay-container:has(.chat-cdk-overlay-container) {
  z-index: 1001;
}

.remove-box-button {
  position: absolute;
  display: none;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  font-size: 25px;
  background-color: var(--lightBackgroundColor);
  color: var(--textColor);
  border-radius: 15px;
  -webkit-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.1);
}

.layout-box:hover .remove-box-button {
  display: block;
}

.remove-box-button svg {
  margin-top: -15px;
}

app-training-database app-exercises-table .main-container {
  padding: 0px !important;
}

.exercisenote-wrapper .note-icon {
  position: absolute;
}

.exercisenote-wrapper .exercisenote-content {
  margin-left: 25px;
}

.exercisenote-wrapper .exercisenote-text {
  margin-bottom: 20px;
}

.setmode-indicator {
  background-color: var(--accentColor);
  color: var(--colorWhite);
  margin-right: 5px;
  border-radius: 5px;
    width: 22px;
    height: 22px;
}

.paymentmethod {
  display: inline-block;
  width: 150px;
  height: 66px;
  margin: 0 10px 0 0;
  cursor: pointer;
  font-size: 12px;
  text-align: left;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  padding: 10px;
}

.paymentmethod.active {
  border: 1px solid black;
}

.paymentmethod-image {
  margin: 0 0 5px 0;
}

.status-indicator.draft {
  background-color: #e8e8e8;
}

.status-indicator.unpaid, .status-indicator.uncompleted, .status-indicator.processing, .status-indicator.initiated, .status-indicator.pending, .status-indicator.manual_transfer_initiated {
  background-color: #fdfd24;
}

.status-indicator.paid, .status-indicator.active {
  background-color: lime;
}

.status-indicator.purchased {
  background-color: rgb(0, 251, 255);
}

.status-indicator.refunded, .status-indicator.expired, .status-indicator.canceled, .status-indicator.voided, .status-indicator.failed, .status-indicator.failed_retrying {
  background-color: #f67326;
}

.sort-attribute {
  cursor: pointer;
}

.sort-attribute.active::before {
  display: inline-block;
  margin: 0 4px 0 0;
}

.sort-attribute.asc.active::before {
  content: "▲";
}

.sort-attribute.desc.active::before {
  content: "▼";
}

.calendar-button {
  background-color: var(--darkerBackgroundColor);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin: 5px 5px 0 5px;
}

.calendar-button.active {
  background-color: var(--accentColor);
  color: var(--textOnAccentColor);
}

.cdk-drag.col-12 {
  padding-left: 0px;
}

.on-field-search {
  margin-left: -35px;
}

.intercom-lightweight-app-launcher, .intercom-dfosxs, .intercom-with-namespace-52k34s {
  bottom: 10px !important;
  right: 10px !important;
}

.close {
  opacity: inherit !important;
  color: inherit !important;
}

/*.intercom-lightweight-app-launcher svg, .intercom-launcher div.intercom-1u7xwp5:first-child svg {
    display: none !important;
}
.intercom-lightweight-app-launcher::before {
    padding: 10px;
    display: block;
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="white" class="bi bi-question-lg" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/></svg>');
}*/

.day-item-tooltip {
  white-space: pre-line;
}

.mobile-app app-task-management .task-management-bar {
  height: calc(100% - env(safe-area-inset-top));
}


#exercises-table-dialog.dialog-wrapper.show .dialog {
  max-width: 1300px;
}

#exercises-table-dialog app-exercises-table {
  width: 100%;
}

#exercises-table-dialog .dialog-content-wrapper {
  margin-top: 50px;
  height: calc(100% - 200px);
  max-height: 75vh;
}

#exercises-table-dialog .dialog-bottombar {
  background-color: var(--lightBackgroundColor);
}

#exercises-table-dialog .layout-box {
  overflow: hidden;
}

@media (max-width: 767.98px) {
  #exercises-table-dialog .dialog {
    margin-top: 10px;
  }
}

.selected-exercises-wrapper {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  overflow-x: auto;
  white-space: nowrap;
}

.selected-exercise {
  display: inline-block;
  background: white;
  border-radius: 15px;
  height: 50px;
  width: 50px;
  margin: 0 0 10px 10px;
  top: 10px;
  position: relative;
  cursor: move;
}

.selected-exercise .thumbnail-image {
  width: 40px;
  margin: 5px;
  position: absolute;
  height: 40px;
}

.selected-exercise .remove-exercise-button {
  position: absolute;
  display: none;
  top: -8px;
  right: -10px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  background-color: var(--lightBackgroundColor);
  color: var(--textColor);
  border-radius: 15px;
  box-shadow: 2px 3px 10px 0px rgba(0, 0, 0, 0.3);
}

.selected-exercise:hover .remove-exercise-button {
  display: flex;
}


.traindoo-app app-training-session-editor .layout-box.sub-box {
  background-color: var(--lightBackgroundColor) !important;
  border-bottom: 0px solid transparent !important;
  border-radius: 20px !important;
}

.traindoo-app app-training-session-editor .layout-box.alternative-exercise-box {
  background-color: var(--boxBackgroundColor) !important;
  border-bottom: 0px solid transparent !important;
  border-radius: 20px !important;
  box-shadow: none;
  padding: 15px 15px !important;
}

.traindoo-app .sessions-column {
  max-width: 300px !important;
}

.traindoo-app app-quick-note-view textarea {
  height: 130px;
}

.traindoo-app .vertical-tab-button {
  overflow: visible !important;
}

.traindoo-app .sessions-column, .traindoo-app app-sessions-column, .traindoo-app .scrollable-session-column {
  overflow-x: visible !important;
}

.traindoo-app .scrollable-session-column {
  max-width: 320px;
}

.traindoo-app app-checkin-table-view .layout-box.sub-box {
  background-color: var(--lightBackgroundColor) !important;
  border-bottom: 0px solid transparent !important;
  border-radius: 20px !important;
}

.traindoo-app app-checkin-table-view .weekly-wrapper .layout-box.sub-box {
  background-color: var(--boxBackgroundColor) !important;
}

i.bi {
  font-size: 22px;
}

i.bi.fontsize-20 {
  font-size: 20px;
}

i.bi.fontsize-18 {
  font-size: 18px;
}

.bi-highlight i.bi {
  -webkit-text-stroke: 0.3px;
}

.traindoo-app app-training-session-editor .bottom-action-buttons {
  bottom: 15px !important;
}

.ngx-spinner-nutritionplan .ngx-spinner-overlay {
  z-index: 999999 !important;
}


.blink-red {
    animation:1s blinker linear infinite;
    -webkit-animation:1s blinker linear infinite;
    -moz-animation:1s blinker linear infinite;
    color: red;
}
@-moz-keyframes blinker
{
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@-webkit-keyframes blinker
{
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@keyframes blinker
{
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}


label:has( input[type="checkbox"]) {
  display:none !important;
}

.NgxEditor, .NgxEditor__MenuBar {
  color: var(--textColor)!important;
  background-color: transparent!important;
}

.NgxEditor__Popup {
  color: var(--textColor)!important;
  background-color: var(--boxBackgroundColor)!important;
}
